<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('preaching_points.index')">
        <local-table
          class="table-hover table-striped"
          :editable="edit"
          :columns="tableFields"
          :data="localTableData"
          :loading="loading"
          :action-options="['view', 'delete']"
          @view-item="viewPoint"
          @new-item="tryAdd"
          @delete-item="tryDelete"
        />
      </va-card>
    </div>
    <va-modal
      ref="preachingPointModal"
      :title="$t('layout.modals.new_preaching_point')"
      v-model="formModal"
      cancel-class="none"
      :ok-text="$t('modal.confirm')"
      :cancel-text="$t('modal.cancel')"
      @ok="addPreachingPoint"
      @cancel="selectedPreachingPoints = []"
    >
      <div class="row">
        <div class="flex xs12">
          <va-select
            v-model="selectedPreachingPoints"
            :label="$t('layout.form.selected_preachingInput')"
            :no-options-text="$t('layout.empty')"
            :loading="isLoading.preachingPoints"
            :disabled="loading"
            :options="preachingPointsList"
            text-by="label"
            searchable
            multiple
          />
        </div>
      </div>
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')

export default {
  name: 'preachings-points',
  components: {
    LocalTable,
  },
  data () {
    return {
      isLoading: {
        preachingPoints: false,
      },
      isError: {
        preachingPoints: false,
      },
      formModal: false,
      preachingPointsList: [],
      selectedPreachingPoints: [],
      preachingPoints: [],
      localTableData: [...this.tableData], // Initialize localTableData with the prop value
    }
  },
  props: {
    edit: {
      type: Boolean,
      default: () => false,
    },
    reportId: {
      type: Number,
      default: () => 0,
    },
    districtId: {
      type: Number,
      default: () => 0,
    },
    team: {
      type: Number,
      default: () => 0,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    tableFields () {
      const columns = [
        {
          name: 'name',
          title: this.$t('tables.headings.name'),
        },
        {
          name: 'code',
          title: this.$t('tables.headings.code'),
        },
        {
          name: 'planted_date',
          title: this.$t('tables.headings.date'),
        },
        {
          name: 'leader.name',
          title: this.$t('tables.headings.leader'),
        },
        {
          name: 'assistance_adults',
          title: this.$t('tables.headings.assistance_adults'),
        },
        {
          name: 'assistance_youngs',
          title: this.$t('tables.headings.assistance_youngs'),
        },
        {
          name: 'assistance_kids',
          title: this.$t('tables.headings.assistance_kids'),
        },
      ]

      if (this.edit) {
        columns.push({
          name: '__slot:actions',
          hidden: this.localTableData.length === 0,
          dataClass: 'text-right',
          width: '8%',
        })
      }

      return columns
    },
  },
  watch: {
    tableData: {
      handler(newVal) {
        this.localTableData = [...newVal];
      },
      deep: true,
    },
  },
  created () {
    if (this.currentUser.can('PreachingPoints', 'index')) {
      this.asyncFindPreaching()
    }
    for (const p of this.tableData) {
      this.preachingPoints.push(p.id)
    }
  },
  methods: {
    viewPoint (data) {
      this.$router.push({ name: 'preachingsView', params: { id: data.id } })
    },
    tryAdd () {
      this.currentPresentation = Object.assign({})
      this.formModal = true
    },
    async tryDelete (data) {
      await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        confirmButtonText: this.$t('layout.buttons.confirm'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            await this.$http.delete(`countries/reports/${data._joinData.id}`)
          } catch (error) {
            this.$swal.showValidationMessage(`Request failed: ${error}`)
          }
          let u = false
          this.loading = false
          try {
            u = await this.$http.get('reports/districts/' + this.reportId)
          } catch (err) {
            this.loading = false
            return
          }

          this.loading = false
          this.preachingPoints = u.data.data.preaching_points
          this.localTableData = u.data.data.preaching_points
        },
      })
    },
    async asyncFindPreaching () {
      if (!this.currentUser.can('PreachingPoints', 'index')) return

      let query = ''
      if (this.districtId !== '') {
        query += `&district=${this.districtId}`
      }
      if (this.reportMonth) {
        query += `&date_since${this.reportMonth.date}`
      }
      query += '&status=0'
      await this.asyncFind(query, 'preachingPoints', 'preaching-points')
      for (const p of this.preachingPointsList) {
        p.label = `${p.code} ${p.name}`
      }
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = true
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    async addPreachingPoint () {
      for (const p of this.selectedPreachingPoints) {
        this.preachingPoints.push(p.id)
      }
      const data = {
        id: this.reportId,
        preaching_points: {
          _ids: this.preachingPoints,
        },
      }

      try {
        await this.$http.put(`proyections/${this.reportId}`, data)
      } catch (e) {
        this.submitError = true
        this.loading = false
      }

      let u = false
      try {
        u = await this.$http.get('reports/districts/' + this.reportId)
      } catch (err) {
        this.loading = false
        return
      }

      this.loading = false
      this.localTableData = u.data.data.preaching_points
    },
  },
}
</script>